<template>
  <AppForm
    :id="id"
    ref="permissionsForm"
    class="p-2"
    :is-loading="isLoading"
    :submit-label="permission ? $t('edit') || 'Edit' : $t('add') || 'Add'"
    :title="
      permission
        ? $t('edit_permission') || 'Edit Permission'
        : $t('add_permission') || 'Add Permission'
    "
    :mode="mode"
    :errors-bag="errorsBag"
    @submit.prevent="handleSubmit"
    @hide="hideForm"
  >
    <AppInput
      v-model="permissionData['name']"
      type="text"
      required
      :label="$t('permission') || 'Permission'"
      rules="required"
      :disabled="mode === 'view'"
    />
    <div class="spacer-15" />
    <AppCheckboxes
      :id="`app-checkboxes`"
      v-model="permissionData['actions']"
      text-field="name"
      value-field="uuid"
      :options="actions"
      :disabled="mode === 'view'"
      :label="$t('actions') || 'Actions'"
      :initial-value="oldSelectedActions"
    />
  </AppForm>
</template>

<script>
import { AppInput, AppCheckboxes, AppForm } from "@/components/form/index";

export default {
  name: "PermissionForm",
  components: {
    AppInput,
    AppCheckboxes,
    AppForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    permission: {
      type: Object,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      default: "add",
    },
  },
  data() {
    return {
      permissionData: {},
    };
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.permissions.isLoading.creating ||
        this.$store.state.permissions.isLoading.updating
      );
    },
    actions() {
      return this.$store.state.actions.data;
    },
    oldSelectedActions() {
      return this.permission
        ? this.permission.actions.map((action) => action.action.uuid)
        : [];
    },
    errorsBag() {
      return this.$store.state.permissions.errors;
    },
  },
  watch: {
    permission: {
      handler(val) {
        if (!val) {
          this.permissionData = {};
          return;
        }
        this.permissionData = {
          ...val,
          name: val.name,
          actions: val.actions.map((item) => item.action.uuid),
        };
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.$store.dispatch("actions/fetchData");
  },
  methods: {
    handleSubmit(e) {
      const actionName = this.permission ? "updateData" : "createData";

      this.$store
        .dispatch(`permissions/${actionName}`, this.permissionData)
        .then((e) => {
          this.hideForm();
        });
    },
    hideForm() {
      this.$emit("hide");
      this.$bvModal.hide(this.id);
      this.permissionData = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
