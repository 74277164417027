<template>
  <div>
    <b-card no-body class="mb-0">
      <AppButton
        class="d-block m-2 ml-auto"
        variant="primary"
        @click="openPermissionForm()"
      >
        <span class="text-nowrap">
          {{ $t("add_new_permission") || "Add New Permission" }}
        </span>
      </AppButton>
      <b-overlay :show="isLoading" rounded>
        <b-table
          class="position-relative"
          :items="permissions"
          hover
          striped
          responsive
          primary-key="id"
          :fields="fields"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(name)="data">
            <div class="text-nowrap">
              <feather-icon
                :icon="data.item.name"
                size="18"
                class="mr-50"
                :class="`text-${data.item.name}`"
              />
              <span class="align-text-top text-capitalize">
                {{ data.item.name }}
              </span>
            </div>
          </template>
          <template #cell(actions)="data">
            <div v-if="data.item.actions">
              <b-badge
                v-for="(action, index) in data.item.actions.slice(0, 5)"
                :key="index"
                variant="secondary"
                pill
                class="mi-end-1"
                @click="openPermissionForm(data.item, 'view')"
              >
                {{ action.action.name }}
              </b-badge>
              <b-badge
                variant="secondary"
                pill
                title="open All Permissions granted to this user"
                @click="openPermissionForm(data.item, 'view')"
              >
                <span
                  v-if="data.item.actions && data.item.actions.length > 5"
                  class="text-capitalize"
                >
                  +{{ data.item.actions.length - 5 }}
                </span>
              </b-badge>
            </div>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                variant="primary"
                class="mb-1"
                @click="openPermissionForm(data.item)"
              >
                {{ $t("edit") || "Edit" }}
              </AppButton>
              <AppButton
                variant="danger"
                @click="openDeletionWarning(data.item)"
              >
                {{ $t("delete") || "Delete" }}
              </AppButton>
            </div>
          </template>
        </b-table>
        <div v-if="permissions && permissions.length" class="m-2">
          <span class="text-muted">
            {{ $t("total_entries", { total: permissions.length }) }}
          </span>
        </div>
      </b-overlay>
    </b-card>

    <PermissionForm
      :id="'permission-form'"
      :permission="activePermission"
      :mode="formMode"
      @hide="hidePermissionForm"
    />
    <deleteWarning
      :show="isDeletionWarnShown"
      :is-loading="isDeleting"
      :action-label="$t('delete') || 'Delete'"
      :cancel-label="$t('cancel') || 'Cancel'"
      :message="$t('are_you_sure') || 'Are You Sure?'"
      @close="cancelDeletionHandler"
      @delete="deletePermisson"
    />
  </div>
</template>

<script>
import {
 BCard, BButton, BTable, BBadge, BOverlay
} from "bootstrap-vue";

import PermissionForm from "./partials/PermissionForm.vue";
import deleteWarning from "@/components/UI/deleteWarning.vue";
import { AppButton } from "@/components/form/index";

export default {
  name: "Permissions",
  components: {
    PermissionForm,
    BCard,
    BButton,
    BTable,
    BBadge,
    BOverlay,
    deleteWarning,
    AppButton,
  },
  data() {
    return {
      activePermission: null,
      fields: [
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "name",
          label: this.$t("name") || "Name",
        },
        {
          key: "actions",
          label: this.$t("actions") || "Actions",
        },
      ],
      isDeletionWarnShown: false,
      formMode: "edit",
    };
  },
  computed: {
    permissions() {
      return this.$store.state.permissions.data;
    },
    isLoading() {
      return this.$store.state.permissions.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.permissions.isLoading.deleting;
    },
  },
  created() {
    this.fetchPermissions();
  },
  methods: {
    openPermissionForm(item = null, mode = "edit") {
      if (item) this.activePermission = item;
      if (mode) this.formMode = mode;
      this.$root.$emit("bv::show::modal", "permission-form");
    },
    hidePermissionForm() {
      this.activePermission = null;
    },
    fetchPermissions() {
      this.$store.dispatch("permissions/fetchData");
    },
    openDeletionWarning(item) {
      this.isDeletionWarnShown = true;
      this.activePermission = item;
    },
    cancelDeletionHandler() {
      this.isDeletionWarnShown = false;
      this.activePermission = null;
    },
    deletePermisson() {
      this.$store
        .dispatch("permissions/deleteData", this.activePermission)
        .then(() => {
          this.activePermission = null;
          this.isDeletionWarnShown = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// .per-page-selector {
//   width: 90px;
// }

.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>

<style lang="scss">
// @import "@core/scss/vue/libs/vue-select.scss";
</style>
